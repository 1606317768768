.slick-track {
  margin-top: 25px;
  margin-bottom: 25px;
}

.slick-current .image {
        transform: scale(1.2) !important;
        padding: 10px;
        transition: transform 1s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
}

.slick-next:before {
  display:'none';
  content: "";
};
.slick-prev:before {
  content: "";
}
.slick-prev:before{
  content: "";
  display:'none';
};
.slick-next:hover{
  opacity:.7 !important;
};
.slick-prev:hover{
  opacity:.7 !important;
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  opacity: 0.9;
}

.image {
  height: 180px;
  border-radius: 10px;
}

.MuiFab-extended {
  width: auto;
  height: 28px !important;
  padding: 0 10px !important;
  min-width: 48px;
  min-height: auto;
  border-radius: 24px;
  text-transform: capitalize !important;
  margin-top: 30px !important;
  margin-left: 30px !important;
  text-align: center;
  cursor: pointer;
  font-size: 0.7rem !important;
  box-shadow: none !important;
}

.range-wrappe{
  display: "flex";
  align-items: center;
  flex-direction: row;

}

input[type=range] {
  -webkit-appearance: none;
  display: block;
  width: 50%;
  background: transparent;
}

input[type=range]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  width: 50%;
  height: 2px;
  color: transparent;
  background: lightgray;
  border-radius: 999px;
  border: none;
}

input[type=range]::-moz-range-track {
  -webkit-appearance: none;
  width: 50%;
  height: 5px;
  color: transparent;
  background: lightgray;
  border-radius: 999px;
  border: none;
  transition: 0.4s all ease-out;
}

input[type=range]::-ms-track {
  -webkit-appearance: none;
  width: 50%;
  height: 5px;
  color: transparent;
  background: lightgray;
  border-radius: 999px;
  border: none;
  transition: 0.4s all ease-out;
}

input[type=range]::-ms-fill-lower {
  display: none;
}

input[type=range]::-ms-fill-upper {
  display: none;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 5px;
  width: 80px;
  cursor: pointer;
  border-radius: 30px;
  background: black;
  box-shadow: 0px 2px 10px -2px black(1);
  transition: 0.4s all ease-out;
}

input[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 5px;
  width: 80px;
  cursor: pointer;
  border-radius: 30px;
  background: black;
  box-shadow: 0px 2px 10px -2px black(1);
  transition: 0.4s all ease-out;
}

input[type=range]::-ms-thumb {
  -webkit-appearance: none;
  height: 5px;
  width: 80px;
  cursor: pointer;
  border-radius: 30px;
  background: black;
  box-shadow: 0px 2px 10px -2px black(1);
  transition: 0.4s all ease-out;
}

.ticks {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  width: 50%;
}